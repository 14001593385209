/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css"
import React from "react"
import { AppProviders } from "./src/components/app-providers"
import "@fontsource/lato"

export const wrapRootElement = ({ element }) => (
  <AppProviders>{element}</AppProviders>
)
